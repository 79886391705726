import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

import { RandomSculptureContents } from "../components/RandomSculptureClub/RandomSculptureContents"

const MockData = [
  {
    mainImage:
      "https://firebasestorage.googleapis.com/v0/b/fir-sculpture.appspot.com/o/randomsculptureclub%2FMainImage.png?alt=media&token=4a8f2efa-981d-4395-a1df-f51c9c3bd895",
    subImage:
      "https://firebasestorage.googleapis.com/v0/b/fir-sculpture.appspot.com/o/randomsculptureclub%2FSubImage.png?alt=media&token=eb90154b-0f62-4c34-93c5-7458a75a7258",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
    price: 540,
  },
  {
    mainImage:
      "https://firebasestorage.googleapis.com/v0/b/fir-sculpture.appspot.com/o/randomsculptureclub%2FMainImage.png?alt=media&token=4a8f2efa-981d-4395-a1df-f51c9c3bd895",
    subImage:
      "https://firebasestorage.googleapis.com/v0/b/fir-sculpture.appspot.com/o/randomsculptureclub%2FSubImage.png?alt=media&token=eb90154b-0f62-4c34-93c5-7458a75a7258",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ",
    price: 540,
  },
]

const RandomSculptureClubPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="ABout" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <div>
        <RandomSculptureContents data={MockData} />
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <RandomSculptureClubPage
        location={props.location}
        data={data}
        {...props}
      />
    )}
  />
)
