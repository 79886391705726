import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { PriceButton } from "../../components/StyledButton/PriceButton"

const ItemContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
`

const ItemImageContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 30px;
  position: relative;
`

const ItemMainImage = styled.img`
  width: 100%;
`

const ItemSubImage = styled.img`
  position: absolute;
  bottom: -20px;
  right: 20px;
  width: 130px;
`

const ItemDescContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 30px;
  position: relative;
  display: flex;
`

const ItemDesc = styled.p`
  width: 100%;
`

const EachColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`

export const RandomSculptureContents = ({ data }) => {
  return (
    <>
      <Grid container spacing={1}>
        {data.map(item => {
          return (
            <>
              <Grid item sm={12} lg={4}>
                <ItemContainer>
                  <ItemImageContainer>
                    <ItemMainImage src={item.mainImage} />
                    <ItemSubImage src={item.subImage} />
                  </ItemImageContainer>
                </ItemContainer>
                <ItemDescContainer>
                  <EachColumn>
                    <ItemDesc>{item.desc}</ItemDesc>
                  </EachColumn>
                  <EachColumn>
                    <PriceButton price={item.price} />
                  </EachColumn>
                </ItemDescContainer>
              </Grid>
            </>
          )
        })}
      </Grid>
    </>
  )
}
