import React from "react"
import styled from "styled-components"

const Container = styled.div`
  height: 30px;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  border: 0.5px solid #49a281;
  color: #49a281;
  border-radius: 15px;
`

const Price = styled.p`
  font-size: 16px;
  margin: 0px;
  color: #49a281;
  margin-right: 3px;
`

export const PriceButton = ({ price }) => {
  return (
    <>
      <Container>
        <Price>{price}</Price>THB
      </Container>
    </>
  )
}
